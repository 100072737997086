var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TemplateForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _vm.showText
                    ? _c("el-form-item", { attrs: { label: "模板编号" } }, [
                        _c("div", {
                          domProps: { textContent: _vm._s(_vm.form.mouldCode) },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "模板编号",
                            rules: [
                              {
                                required: true,
                                message: "请输入模板编号",
                                trigger: "change",
                              },
                            ],
                            prop: "mouldCode",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入模板编号",
                              maxlength: 100,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.mouldCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mouldCode", $$v)
                              },
                              expression: "form.mouldCode",
                            },
                          }),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "模板名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入模板名称",
                            trigger: "change",
                          },
                        ],
                        prop: "mouldName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入模板名称",
                          maxlength: 100,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.mouldName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mouldName", $$v)
                          },
                          expression: "form.mouldName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.showText
                    ? _c("el-form-item", { attrs: { label: "业务类型" } }, [
                        _c("div", {
                          domProps: {
                            textContent: _vm._s(_vm.businessTypeName),
                          },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "业务类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择业务类型",
                                trigger: "change",
                              },
                            ],
                            prop: "businessType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.businessTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.businessType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "businessType", $$v)
                              },
                              expression: "form.businessType",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm.showText
                    ? _c("el-form-item", { attrs: { label: "模板类型" } }, [
                        _c("div", {
                          domProps: { textContent: _vm._s(_vm.mouldTypeName) },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "模板类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择模板类型",
                                trigger: "change",
                              },
                            ],
                            prop: "mouldType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.mouldTypeOps,
                              width: _vm.width,
                            },
                            on: { change: _vm.onMouldTypeChange },
                            model: {
                              value: _vm.form.mouldType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mouldType", $$v)
                              },
                              expression: "form.mouldType",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm.form.mouldType === "download_page_mould"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属APP",
                            rules: [
                              {
                                required: true,
                                message: "请选择模板类型",
                                trigger: "change",
                              },
                            ],
                            prop: "appCode",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: "查询APP名称",
                                  width: _vm.width,
                                },
                                on: { onChange: _vm.onAppChange },
                                model: {
                                  value: _vm.form.appCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "appCode", $$v)
                                  },
                                  expression: "form.appCode",
                                },
                              },
                              "v-select2",
                              _vm.appParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.curAppItem.appLogo &&
                  _vm.form.mouldType === "download_page_mould"
                    ? _c("el-form-item", { attrs: { label: "APP图标" } }, [
                        _c("img", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            "object-fit": "cover",
                          },
                          attrs: { src: _vm.curAppItem.appLogo, alt: "" },
                        }),
                      ])
                    : _vm._e(),
                  _vm.curAppItem.introduce &&
                  _vm.form.mouldType === "download_page_mould"
                    ? _c("el-form-item", { attrs: { label: "APP介绍" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.curAppItem.introduce))]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "属性信息" } },
                [
                  _vm._l(_vm.form.listDetail, function (item, index) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "list" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "key" + (index + 1),
                              rules: [
                                {
                                  required: true,
                                  message: "请输入key",
                                  trigger: "change",
                                },
                              ],
                              prop: "listDetail." + index + ".key",
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                placeholder: "请输入key",
                                maxlength: 100,
                                width: _vm.width,
                                disabled: item.immutable,
                              },
                              model: {
                                value: item.key,
                                callback: function ($$v) {
                                  _vm.$set(item, "key", $$v)
                                },
                                expression: "item.key",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "输入类型",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择输入类型",
                                  trigger: "change",
                                },
                              ],
                              prop: "listDetail." + index + ".valueType",
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                disabled: item.immutable,
                                options: _vm.valueTypeOps,
                                width: 120,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.valueTypeChange(item, index)
                                },
                              },
                              model: {
                                value: item.valueType,
                                callback: function ($$v) {
                                  _vm.$set(item, "valueType", $$v)
                                },
                                expression: "item.valueType",
                              },
                            }),
                          ],
                          1
                        ),
                        item.valueType === 1
                          ? _c(
                              "el-form-item",
                              {
                                key: "1",
                                staticClass: "form-item",
                                attrs: {
                                  label: "value",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入文本",
                                      trigger: "change",
                                    },
                                  ],
                                  prop: "listDetail." + index + ".value",
                                },
                              },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    placeholder: "请输入文本",
                                    rows: 3,
                                    maxlength: 500,
                                    width: _vm.width,
                                  },
                                  model: {
                                    value: item.value,
                                    callback: function ($$v) {
                                      _vm.$set(item, "value", $$v)
                                    },
                                    expression: "item.value",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.valueType === 2
                          ? _c(
                              "el-form-item",
                              {
                                key: "2",
                                staticClass: "form-item",
                                attrs: {
                                  label: "value",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请上传图片",
                                      trigger: "change",
                                    },
                                  ],
                                  prop: "listDetail." + index + ".value",
                                },
                              },
                              [
                                _c("v-uploader", {
                                  attrs: {
                                    action: _vm.uploadURL,
                                    imgUrls: item.value,
                                  },
                                  on: {
                                    "update:imgUrls": function ($event) {
                                      return _vm.$set(item, "value", $event)
                                    },
                                    "update:img-urls": function ($event) {
                                      return _vm.$set(item, "value", $event)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.valueType === 3
                          ? _c(
                              "el-form-item",
                              {
                                key: "3",
                                staticClass: "form-item",
                                attrs: {
                                  label: "value",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择颜色",
                                      trigger: "change",
                                    },
                                  ],
                                  prop: "listDetail." + index + ".value",
                                },
                              },
                              [
                                _c("el-color-picker", {
                                  attrs: { "show-alpha": true },
                                  model: {
                                    value: item.value,
                                    callback: function ($$v) {
                                      _vm.$set(item, "value", $$v)
                                    },
                                    expression: "item.value",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: { label: "备注" },
                          },
                          [
                            _c("v-textarea", {
                              attrs: {
                                placeholder: "请输入备注",
                                rows: 3,
                                maxlength: 200,
                                width: _vm.width,
                                disabled: item.immutable,
                              },
                              model: {
                                value: item.remark,
                                callback: function ($$v) {
                                  _vm.$set(item, "remark", $$v)
                                },
                                expression: "item.remark",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "button-reduce" },
                          [
                            (_vm.form.mouldType === "download_page_mould" &&
                              !item.immutable) ||
                            _vm.form.mouldType !== "download_page_mould"
                              ? _c("v-button", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.form.listDetail.length > 1,
                                      expression: "form.listDetail.length > 1",
                                    },
                                  ],
                                  attrs: { type: "danger", text: "删除属性" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reduceAttr(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-form-item",
                    [
                      _c("v-button", {
                        attrs: { text: "新增属性" },
                        on: { click: _vm.increaseAttr },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }