<template>
  <div class="TemplateForm">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <col2-detail>

        <col2-block title="基础信息">

          <el-form-item v-if="showText" label="模板编号">
            <div v-text="form.mouldCode" />
          </el-form-item>
          <el-form-item v-else label="模板编号" :rules="[{ required: true, message: '请输入模板编号', trigger: 'change' }]" prop="mouldCode">
            <v-input v-model="form.mouldCode" placeholder="请输入模板编号" :maxlength="100" :width="width" />
          </el-form-item>

          <el-form-item label="模板名称" :rules="[{ required: true, message: '请输入模板名称', trigger: 'change' }]" prop="mouldName">
            <v-input v-model="form.mouldName" placeholder="请输入模板名称" :maxlength="100" :width="width" />
          </el-form-item>

          <el-form-item v-if="showText" label="业务类型">
            <div v-text="businessTypeName" />
          </el-form-item>
          <el-form-item v-else label="业务类型" :rules="[{ required: true, message: '请选择业务类型', trigger: 'change' }]" prop="businessType">
            <v-select v-model="form.businessType" :options="businessTypeOps" :width="width" />
          </el-form-item>

          <el-form-item v-if="showText" label="模板类型">
            <div v-text="mouldTypeName" />
          </el-form-item>
          <el-form-item v-else label="模板类型" :rules="[{ required: true, message: '请选择模板类型', trigger: 'change' }]" prop="mouldType">
            <v-select v-model="form.mouldType" :options="mouldTypeOps" :width="width" @change="onMouldTypeChange" />
          </el-form-item>
          <el-form-item v-if="form.mouldType === 'download_page_mould'" label="所属APP" :rules="[{ required: true, message: '请选择模板类型', trigger: 'change' }]" prop="appCode">
            <v-select2 v-model="form.appCode" v-bind="appParams" placeholder="查询APP名称" :width="width" @onChange="onAppChange"></v-select2>
          </el-form-item>
          <el-form-item label="APP图标" v-if="curAppItem.appLogo && form.mouldType === 'download_page_mould'">
<!--            <v-preview :imgStyle="{width: '100px', height: '100px', objectFit: 'cover'}" :imgs="[curAppItem.appLogo]"></v-preview>-->
            <img style="width: 100px;height: 100px;object-fit: cover;" :src="curAppItem.appLogo" alt=""/>
          </el-form-item>
          <el-form-item label="APP介绍" v-if="curAppItem.introduce && form.mouldType === 'download_page_mould'">
            <div>{{curAppItem.introduce}}</div>
          </el-form-item>
        </col2-block>

        <col2-block title="属性信息">

          <div class="list" v-for="(item, index) in form.listDetail" :key="item.id">

            <el-form-item :label="'key' + (index + 1)" :rules="[{ required: true, message: '请输入key', trigger: 'change'}]" :prop="'listDetail.' + index + '.key'">
              <v-input v-model="item.key" placeholder="请输入key" :maxlength="100" :width="width" :disabled="item.immutable" />
            </el-form-item>

            <el-form-item label="输入类型" :rules="[{ required: true, message: '请选择输入类型', trigger: 'change'}]" :prop="'listDetail.' + index + '.valueType'">
              <v-select v-model="item.valueType" :disabled="item.immutable" :options="valueTypeOps" @change="valueTypeChange(item, index)" :width="120" />
            </el-form-item>

            <el-form-item v-if="item.valueType === 1" key="1" class="form-item" label="value" :rules="[{ required: true, message: '请输入文本', trigger: 'change'}]" :prop="'listDetail.' + index + '.value'">
              <v-textarea v-model="item.value" placeholder="请输入文本" :rows="3" :maxlength="500" :width="width" />
            </el-form-item>

            <el-form-item v-if="item.valueType === 2" key="2" class="form-item" label="value" :rules="[{ required: true, message: '请上传图片', trigger: 'change'}]" :prop="'listDetail.' + index + '.value'">
              <v-uploader :action="uploadURL" :imgUrls.sync="item.value" />
            </el-form-item>

            <el-form-item v-if="item.valueType === 3" key="3" class="form-item" label="value" :rules="[{ required: true, message: '请选择颜色', trigger: 'change'}]" :prop="'listDetail.' + index + '.value'">
              <el-color-picker v-model="item.value" :show-alpha="true" />
            </el-form-item>

            <el-form-item label="备注" class="form-item">
              <v-textarea v-model="item.remark" placeholder="请输入备注" :rows="3" :maxlength="200" :width="width" :disabled="item.immutable" />
            </el-form-item>

            <div class="button-reduce">
              <v-button v-if="(form.mouldType === 'download_page_mould' && !item.immutable) || form.mouldType !== 'download_page_mould'" v-show="form.listDetail.length > 1" type="danger" text="删除属性" @click="reduceAttr(index)" />
            </div>

          </div>

          <el-form-item>
            <v-button text="新增属性" @click="increaseAttr" />
          </el-form-item>

        </col2-block>

      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { uploadURL } from 'common/api'
import { queryDetailURL, submitURL, searchAPPURL } from './api'
import { setBusinessTypeOps, setMouldTypeOps, setValueTypeOps } from './map'
import { ColorPicker } from 'element-ui'
import { vUploader, vPreview } from 'components/control'
import { Col2Block, Col2Detail } from '@/components/bussiness'

const DownloadTempImmutableKeys = ['download_btn_color', 'header_bg_color', 'app_name_text_color', 'version_text_color', 'example_pic', 'extra_title', 'extra_info', 'bottom_bg_color', 'bottom_text_color'] // 下载模板不可更改的key

export default {
  name: 'TemplateForm',
  components: {
    elColorPicker: ColorPicker,
    vUploader,
    Col2Block,
    Col2Detail,
    vPreview
  },
  data () {
    return {
      width: 200,
      pageType: '',
      showText: false,
      uploadURL: `${uploadURL}?module=icon`,
      submitConfig: {
        queryUrl: queryDetailURL,
        submitUrl: submitURL,
        submitMethod: 'POST'
      },
      businessTypeOps: setBusinessTypeOps(),
      mouldTypeOps: setMouldTypeOps(),
      valueTypeOps: setValueTypeOps(),
      businessTypeName: '',
      mouldTypeName: '',
      newAppData: undefined,
      form: {
        id: undefined,
        mouldCode: '',
        mouldName: '',
        businessType: undefined,
        mouldType: undefined,
        listDetail: [],
        appCode: ''
      },
      appParams: {
        searchUrl: searchAPPURL,
        request: {
          text: 'appName',
          value: 'id'
        },
        response: {
          text: 'appName'
        }
      },
      curAppItem: {}
    }
  },
  mounted () {
    const { id, copyId, newAppData } = this.$route.query
    this.newAppData = newAppData
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.pageType = 'edit'
      this.showText = true
      this.$refs.formPanel.getData({
        id
      })
    } else {
      if (copyId !== undefined) {
        this.$setBreadcrumb('复制')
        this.pageType = 'copy'
        this.$refs.formPanel.getData({
          id: copyId
        })
      } else {
        this.$setBreadcrumb('新增')
        this.pageType = 'create'
        if (this.newAppData !== undefined) {
          this.form.businessType = this.newAppData.businessType
          this.form.mouldType = this.newAppData.mouldType
          this.form.appCode = this.newAppData.appCode
          this.curAppItem.appLogo = this.newAppData.appLogo
          this.businessTypeName = this.newAppData.businessTypeName
          this.mouldTypeName = this.newAppData.mouldTypeName
        } else {
          this.form.businessType = this.businessTypeOps[0].value
          this.form.mouldType = this.mouldTypeOps[0].value
        }
        this.increaseAttr()
      }
    }
  },
  methods: {
    update (data) {
      if (this.pageType === 'edit') {
        this.businessTypeName = data.businessTypeName
        this.mouldTypeName = data.mouldTypeName
      } else if (this.pageType === 'copy') {
        data.id = undefined
      }
      Object.keys(this.form).forEach(key => {
        this.$set(this.form,key,data[key])
      })
      if (data.listDetail && data.listDetail.length) {
        data.listDetail.forEach((item, index) => {
          item.id = this.createId() + index
          if (this.form.mouldType === 'download_page_mould') {
            // 下载模板元信息字段的key值不能被删除，也不能更改值
            item.immutable = DownloadTempImmutableKeys.includes(item.key)
          }
        })
      }
    },
    async submitSuccess (data, msg) {
      const theme = {}
      this.form.listDetail.forEach(item => {
        theme[item.key] = item.value
      })
      let remakeTheme = {}
      for (let [key,item] of Object.entries(theme)){
        if(/^\$--/.test(key)){
          if(/^\$--button/.test(key)){
            remakeTheme[key+'-border-color'] = item
            remakeTheme[key+'-background-color'] = item
          }else{
            remakeTheme[key] =item
          }
        }
      }
      const { code } = await this.$axios.post("https://miniprogram.4001113900.com/tmakeTheme",remakeTheme)
      if(code == 200){
        this.$message.success("主题生成成功")
        if (this.newAppData !== undefined) {
          this.$router.go(-2)
        } else {
          this.$router.go(-1)
        }
      }else{
        this.$message.error("主题生成失败，请重试")
        return false
      }
    },
    async submitBefore (data) {
      if (data.listDetail && data.listDetail.length) {
        data.listDetail.forEach((item, index) => {
          delete item.id
        })
      }
      if (this.pageType === 'edit') {
        const result = await this.$confirm('修改后将影响模板显示内容，请确认。')
        if (!result) {
          return false
        }
      }
      return true
    },
    createId () {
      return new Date().valueOf().toString()
    },
    valueTypeChange (item, index) {
      item.value = ''
      this.$nextTick(() => {
        this.$refs.formPanel.clearValidate('listDetail.' + index + '.value')
      })
    },
    increaseAttr () {
      const defaultValueType = this.valueTypeOps[0].value
      this.form.listDetail.push({
        id: this.createId(),
        key: '',
        value: '',
        valueType: defaultValueType,
        remark: ''
      })
    },
    reduceAttr (index) {
      this.form.listDetail.splice(index, 1)
    },
    onAppChange (item) {
      if (item) {
        this.form.appName = item.appName
        this.curAppItem = item
      } else {
        this.form.appName = undefined
        this.curAppItem = {}
      }
    },
    onMouldTypeChange (code) {
      if (code === 'download_page_mould') {
        this.form.listDetail = [
          {
            id: this.createId(),
            key: 'download_btn_color',
            value: '#0071F5',
            valueType: this.valueTypeOps[2].value,
            remark: '下载按钮颜色',
            immutable: true
          },
          {
            id: this.createId() + 1,
            key: 'header_bg_color',
            value: '#ECEDEE',
            valueType: this.valueTypeOps[2].value,
            remark: '头部背景色',
            immutable: true
          },
          {
            id: this.createId() + 6,
            key: 'app_name_text_color',
            value: '#333333',
            valueType: this.valueTypeOps[2].value,
            remark: 'APP名称字体颜色',
            immutable: true
          },
          {
            id: this.createId() + 8,
            key: 'version_text_color',
            value: '#333333',
            valueType: this.valueTypeOps[2].value,
            remark: '版本号字体颜色',
            immutable: true
          },
          {
            id: this.createId() + 2,
            key: 'example_pic',
            value: '',
            valueType: this.valueTypeOps[1].value,
            remark: '示例图片',
            immutable: true
          },
          {
            id: this.createId() + 3,
            key: 'extra_title',
            value: '',
            valueType: this.valueTypeOps[0].value,
            remark: '额外信息标题',
            immutable: true
          },
          {
            id: this.createId() + 4,
            key: 'extra_info',
            value: '',
            valueType: this.valueTypeOps[0].value,
            remark: '额外信息项, 用【\\n】将多个信息项分隔开,例如：服务超便利\\n生活超智能',
            immutable: true
          },
          {
            id: this.createId() + 5,
            key: 'bottom_bg_color',
            value: '#858585',
            valueType: this.valueTypeOps[2].value,
            remark: '底部背景色',
            immutable: true
          },
          {
            id: this.createId() + 7,
            key: 'bottom_text_color',
            value: '#FFFFFF',
            valueType: this.valueTypeOps[2].value,
            remark: '底部文本颜色',
            immutable: true
          }
        ].concat(this.form.listDetail.filter(v=>!v.immutable))
      } else {
      this.form.listDetail = this.form.listDetail.filter(v=>!v.immutable)
      if(this.form.listDetail.length == 0 )
        this.increaseAttr()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.TemplateForm {
  .list {
    display: flex;
    .form-item {
      min-width: 368px;
    }
  }
  .button-reduce {
    margin-left: 40px;
  }
}
</style>
