import { mapHelper } from 'common/utils'

// 业务类型
const businessType = [
  {
    text: 'H5模板',
    value: 2
  },
  {
    text: '管理后台',
    value: 7
  },
  {
    text: '分享平台',
    value: 4
  }
]

const {
  map: businessTypeMap,
  setOps: setBusinessTypeOps
} = mapHelper.setMap(businessType)

// 模板类型
const mouldType = [
  {
    text: '访客通行',
    value: 'visitor_mould'
  },
  {
    text: 'H5应用授权',
    value: 'h5_auth_mould'
  },
  {
    text: '管理后台皮肤',
    value: 'manage_skin_mould'
  },
  {
    text: '分享平台账号微信',
    value: 'sharing_plat_account_weixin'
  },
  {
    text: '分享平台账号QQ',
    value: 'sharing_plat_account_qq'
  },
  {
    text: '下载页面',
    value: 'download_page_mould'
  }
]

const {
  map: mouldTypeMap,
  setOps: setMouldTypeOps
} = mapHelper.setMap(mouldType)

// 输入类型
const valueType = [
  {
    text: '文本输入',
    value: 1
  },
  {
    text: '图片上传',
    value: 2
  },
  {
    text: '颜色选择',
    value: 3
  }
]

const {
  map: valueTypeMap,
  setOps: setValueTypeOps
} = mapHelper.setMap(valueType)

export {
  businessTypeMap,
  setBusinessTypeOps,
  mouldTypeMap,
  setMouldTypeOps,
  valueTypeMap,
  setValueTypeOps
}
