// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}businessMouldInfo/pageList`
// 操作人 select2
const getUserListURL = `${API_CONFIG.butlerBaseURL}agreement/user/search/keyword`

// 查询详情
const queryDetailURL = `${API_CONFIG.controlBaseURL}businessMouldInfo/getBusinessMouldInfoDetail`
// 新增/编辑
const submitURL = `${API_CONFIG.controlBaseURL}businessMouldInfo/business/mould/info/edit`
//
const searchAPPURL = `${API_CONFIG.butlerBaseURL}appInfo/selectAppinfo`

export {
  getListURL,
  getUserListURL,
  queryDetailURL,
  submitURL,
  searchAPPURL
}
